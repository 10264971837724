import HttpService from "../HttpService";
import DisciplineCategory from "@/models/sporteventCalculation/DisciplineCategory";
import BaseService from "../BaseService";

class DisciplineCategoryService extends BaseService<DisciplineCategory> {
  public url: string;

  constructor(url = "sportevent-calculation/disciplinecategory/") {
    super(url, "");
    this.url = url;
  }
}

export default new DisciplineCategoryService();
